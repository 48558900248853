<template>
  <div id="CategoryModal" style="display: inline">
    <a href="#" v-b-modal="modalID" title="Change resolved date" class="btn btn-outline-info btn-sm m-2">Resolved Date</a>

    <!-- arrow-right -->
    <b-modal :id="modalID" title="Change resolved date" size="sm" hide-footer no-close-on-backdrop>
      <div>
        <form @submit.prevent="handleSubmit">
          <div class="form-group mb-3">
            <label for="email">Date</label>
            <input
              v-model="datePicked"
              type="date"
              class="form-control"
              min="2021-01-01"
              :max="maxDate"
              :class="{ 'is-invalid': $v.datePicked.$error, 'is-valid': !$v.datePicked.$invalid }"
            />
            <div class="invalid-feedback">
              <span v-if="!$v.datePicked.required">Date is required.</span>
            </div>
          </div>
          <div class="form-group mb-3">
            <label for="email">Time</label>
            <input v-model="timePicked" type="time" class="form-control" />
          </div>
          <div class="mt-2">
            <button v-if="!spinner" type="submit" class="btn btn-warning">Change Date</button>
            <b-spinner v-if="spinner" variant="warning" label="Spinning"></b-spinner>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ResolutionDateModal',

  data() {
    return {
      spinner: false,
      modalID: 'ResolutionDateModal',
      datePicked: '',
      timePicked: '10:00'
    }
  },

  props: {
    ticket: { type: Object }
  },

  mounted() {
    /* axios.get('/categories').then(res => {
      if (res.data.status == 'success') {
        this.categories = res.data.categories
      }
      this.spinner = false
    }) */
  },

  methods: {
    handleSubmit() {
      this.spinner = true

      console.log(this.datePicked)
      console.log(this.timePicked)

      this.$v.$touch()
      if (this.$v.$invalid) {
        this.spinner = false
        return
      }

      let datetime = this.datePicked + ' ' + this.timePicked + ':00'

      let formData = new FormData()
      formData.append('datetime', datetime)
      formData.append('ref', this.ticket.ref)

      axios.post('/first-level/change-resolved-date', formData).then(res => {
        if (res.data.status == 'success') {
          this.$swal('Resolved Date', res.data.message, 'success')
          this.$emit('resolvedDateChanged', res.data.updatedAt)
          this.$bvModal.hide(this.modalID)
        } else {
          this.$swal('Ooops!', res.data.message, 'error')
        }
        this.spinner = false
      })
    }
  },

  computed: {
    client() {
      return this.$store.state.client
    },

    authUser() {
      return this.$store.state.authUser
    },

    maxDate() {
      // get a date in this format: 2021-01-01
      return new Date().toISOString().split('T')[0]
    }
  },

  validations: {
    datePicked: { required }
  }
}
</script>

<style scoped>
.modal-body {
}
.col {
  height: 450px;
  overflow: auto;
  background-color: #eee;
}
.secondCol {
  border-left: 3px solid #ccc;
  border-right: 3px solid #ccc;
}
.list-group {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.escalateButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
}
.list-group-item {
  font-size: 0.8rem;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #4eadea;
  border-color: #4eadea;
}
</style>
