<template>
  <div id="CustomerLayout">
    <b-navbar toggleable="lg" type="light" variant="light">
      <!-- Logo -->
      <router-link :to="{ name: 'HomeCustomer' }" class="navbar-brand text-muted">
        <img alt="PayAttitude" src="../assets/pay-attitude-logo.png" height="50" />
        &nbsp;&nbsp; Customer Support
      </router-link>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <li class="nav-item">
            <router-link :to="{ name: 'HomeCustomer' }" class="nav-link">
              <b-icon-house></b-icon-house>
              Home
            </router-link>
          </li>

          <li class="nav-item">
            <router-link :to="{ name: 'TicketCreateCustomer' }" class="nav-link">
              <b-icon-sticky></b-icon-sticky>
              Open New Ticket
            </router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link :to="{ name: 'TicketsCustomer', params: { status: 'open' } }" class="nav-link">
              <b-icon-circle-fill class="text-danger"></b-icon-circle-fill>
              Open
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'TicketsCustomer', params: { status: 'resolved' } }" class="nav-link">
              <b-icon-circle-fill class="text-success"></b-icon-circle-fill>
              Resolved
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'TicketsCustomer', params: { status: 'reopened' } }" class="nav-link">
              <b-icon-circle-fill class="text-danger"></b-icon-circle-fill>
              Reopen
            </router-link>
          </li> -->
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <template #button-content> <b-icon-person-circle></b-icon-person-circle> {{ authUser.Name }} </template>
            <li>
              <router-link :to="{ name: 'ProfileCustomer' }" class="dropdown-item">
                <b-icon-person></b-icon-person>
                Profile
              </router-link>
            </li>
            <Logout kind="list" />
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <router-view />
  </div>
</template>

<script>
import Logout from '@/components/Logout.vue'

export default {
  name: 'CustomerLayout',

  components: {
    Logout
  },

  computed: {
    authUser() {
      return this.$store.state.authUser
    },

    year() {
      let d = new Date()
      return d.getFullYear()
    }
  }
}
</script>
